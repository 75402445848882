import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				KickIt Arena
			</title>
			<meta name={"description"} content={"Розташована в зручному місці, наша арена оснащена першокласними зручностями, які підвищать ваш футбольний досвід. Завдяки простому онлайн-бронюванню та гнучким часовим слотам, KickIt Arena - це ваше найкраще місце для всього, що стосується футболу."} />
			<meta property={"og:title"} content={"KickIt Arena"} />
			<meta property={"og:description"} content={"Розташована в зручному місці, наша арена оснащена першокласними зручностями, які підвищать ваш футбольний досвід. Завдяки простому онлайн-бронюванню та гнучким часовим слотам, KickIt Arena - це ваше найкраще місце для всього, що стосується футболу."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b9c1e4e5d2770018970971/images/2-1.jpg?v=2024-08-12T10:33:03.249Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});